@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
/* @import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";
@import "assets/css/default/app.min.css"; */

.pac-container {
    z-index: 9999!important;
    border-top: none;
}

.Toastify__toast-body > div:last-child{
  font-weight: 600;
}

.Toastify__toast-theme--light {
  box-shadow: 1px 5px 6px #666;
} 

.gm-style .gm-style-iw-c {
  width:340px!important;
  max-width: inherit!important;
  
}

@media only screen and (max-width: 500px) {
  .gm-style .gm-style-iw-c {
    transform: translate3d(-50%,-70%,0)!important;
  }
}

.jARkgs {
  height: 345px!important;
}

.dropdown-menu {
  z-index: 9999!important;
}

.swal2-title {
  font-size: 20px!important;
  color: #221C46!important;
}

.swal2-styled.swal2-confirm {
  background-color: #FF7375!important;
  color: #221C46!important;
  border-radius: 0!important;
  border: 1px solid #ff7375!important;
}
.swal2-styled.swal2-deny{
  background-color: transparent!important;
  border: 1px solid #FF7375!important;
  color: #221C46!important;
  border-radius: 0!important;
}

.css-ego9h2-control, .css-ego9h2-control:hover, .css-hphidw-control {
  border-color: #221C46!important
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}


